<template>

  <div class="">
    <!-- Table Container Card -->
    <div class="shadow-sm">
      <b-card
        no-body
        class="mb-0"
      >
        <b-table
          v-if="returnTable"
          ref="refAllInventoryRequestsTable"
          class="position-relative has_padding event_list"
          :items="fetchInventoryRequestedItem"
          responsive
          :fields="returnTableColumns"
          show-empty
          no-sort-reset
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          sort-compare-locale="zh"
          :sort-compare-options="{ numeric: true, sensitivity: 'base', localeMatcher: 'best fit' }"
          :sort-compare="sortCompare"
          empty-text="No matching records found"
        >
          <!-- Column: Role -->
          <template #cell(item)="data">
            <div class="d-flex">
              <b-img
                class="mb-1 mb-sm-0 user__avatar img-preview-block"
                :src="data.item.item.image ? data.item.item.image : defaultImage"
              />
              <div
                class="ml-1"
              >
                <span class="text-bold-black">{{ data.item.item.name || '-' }}</span>
                <br>
                <span class="text-muted">{{ data.item.item.sku || '-' }}</span>
              </div>
            </div>
          </template>

          <template #cell(brand)="data">
            <span>{{ data.item.item.brand }}</span>
          </template>

          <template #cell(store)="data">
            <span>
              {{ data.item.store.name }}
              <br>
              {{ data.item.store.department ? data.item.store.department.name : '' }}
            </span>
          </template>

          <template #cell(quantity)="data">
            <span>{{ resolveInventoryRequestItemQuantity(data.item.quantity) }}</span>
          </template>

          <template #cell(fileNo)="data">
            <div
              v-if="data.item.fileNo"
              class="text-nowrap"
            >
              <span class="">{{ data.item.fileNo }}</span>
              <br>
              <b-button
                variant="flat-primary"
                @click="sendFileNo(data.item.fileNo)"
              >
                Check history
              </b-button>
            </div>
            <div
              v-else
              class="text-nowrap"
            >
              <span class="">-</span>
              <br>
              <b-button
                disabled
                variant="flat-primary"
              >
                Check history
              </b-button>
            </div>
          </template>
        </b-table>
        <b-table
          v-else
          ref="refAllInventoryRequestsTable"
          class="position-relative has_padding event_list"
          :items="fetchInventoryRequestedItem"
          responsive
          :fields="tableColumns"
          show-empty
          no-sort-reset
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          sort-compare-locale="zh"
          :sort-compare-options="{ numeric: true, sensitivity: 'base', localeMatcher: 'best fit' }"
          :sort-compare="sortCompare"
          empty-text="No matching records found"
        >
          <!-- Column: Role -->
          <template #cell(item)="data">
            <div class="d-flex">
              <b-img
                class="mb-1 mb-sm-0 user__avatar img-preview-block"
                :src="data.item.item.image ? data.item.item.image : defaultImage"
              />
              <div
                class="ml-1"
              >
                <span class="text-bold-black">{{ data.item.item.name || '-' }}</span>
                <br>
                <span class="text-muted">{{ data.item.item.sku || '-' }}</span>
              </div>
            </div>
          </template>

          <template #cell(brand)="data">
            <span>{{ data.item.item.brand }}</span>
          </template>

          <template #cell(store)="data">
            <span>
              {{ data.item.store.name }}
              <br>
              {{ data.item.store.department ? data.item.store.department.name : '' }}
            </span>
          </template>

          <template #cell(quantity)="data">
            <span>{{ resolveInventoryRequestItemQuantity(data.item.quantity) }}</span>
          </template>

          <template #cell(fileNo)="data">
            <div
              v-if="data.item.fileNo"
              class="text-nowrap"
            >
              <span class="">{{ data.item.fileNo }}</span>
              <br>
              <b-button
                variant="flat-primary"
                @click="sendFileNo(data.item.fileNo)"
              >
                Check history
              </b-button>
            </div>
            <div
              v-else
              class="text-nowrap"
            >
              <span class="">-</span>
              <br>
              <b-button
                disabled
                variant="flat-primary"
              >
                Check history
              </b-button>
            </div>
          </template>
        </b-table>
      </b-card>
    </div>
    <div class="d-flex justify-content-between mt-2">
      <p><span class="text-bold-black mr-75">Inventory Item(s)</span> {{ totalItems }}</p>
      <p><span class="text-bold-black">Total Quantity</span> <span class="ml-75">{{ totalQuantity }}</span></p>
    </div>
  </div>
</template>

<script>
import {
  BCard, BTable, BImg, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BTable,
    BImg,
    BButton,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  props: {
    fetchInventoryRequestedItem: {
      type: [Array, null],
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
    totalItems: {
      type: Number,
      default: 0,
    },
    totalQuantity: {
      type: Number,
      default: 0,
    },
    returnTable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // eslint-disable-next-line global-require
      defaultImage: require('@/assets/images/placeholder/placeholder_image.png'),
      sortBy: 'item',
      sortDesc: false,
      itemFileNo: [],
      tableColumns: [
        {
          key: 'item',
          label: 'Item',
          sortable: true,
        },
        {
          key: 'brand',
          label: 'brand',
          sortable: true,
          sortByFormatted: true,
        },
        {
          key: 'store',
          label: 'store',
          sortable: true,
        },
        {
          key: 'quantity',
          label: 'quantity',
          sortable: true,
        },
        {
          key: 'fileNo',
          label: 'item file no',
          sortable: true,
        },
      ],
      returnTableColumns: [
        {
          key: 'item',
          label: 'Item',
          sortable: true,
        },
        {
          key: 'brand',
          label: 'brand',
          sortable: true,
          sortByFormatted: true,
        },
        {
          key: 'store',
          label: 'store',
          sortable: true,
        },
        {
          key: 'quantity',
          label: 'quantity',
          sortable: true,
        },
        {
          key: 'returnedQuantity',
          label: 'returned',
          sortable: true,
        },
        {
          key: 'fileNo',
          label: 'item file no',
          sortable: true,
        },
      ],
    }
  },
  methods: {
    sortCompare(aRow, bRow, key, sortDesc, formatter, compareOptions, compareLocale) {
      let a = ''
      let b = ''
      if (key === 'item') {
        a = aRow.item.name
        b = bRow.item.name
      } else if (key === 'brand') {
        a = aRow.item.brand
        b = bRow.item.brand
      } else if (key === 'store') {
        a = aRow.store.name
        b = bRow.store.name
      } else if (key === 'quantity') {
        a = aRow.totalQuantity
        b = bRow.totalQuantity
      } else if (key === 'returnedQuantity') {
        a = aRow.returnedQuantity
        b = bRow.returnedQuantity
      } else if (key === 'fileNo') {
        a = aRow.fileNo
        b = bRow.fileNo
      }
      if (
        (typeof a === 'number' && typeof b === 'number')
        || (a instanceof Date && b instanceof Date)
      ) {
        // eslint-disable-next-line no-nested-ternary
        return a < b ? -1 : a > b ? 1 : 0
      }
      return a.localeCompare(b, compareLocale, compareOptions)
    },
    toString(value) {
      if (value === null || typeof value === 'undefined') {
        return ''
      } if (value instanceof Object) {
        return Object.keys(value)
          .sort()
          .map(key => toString(value[key]))
          .join(' ')
      }
      return String(value)
    },
    resolveInventoryRequestItemQuantity(quantity) {
      if (quantity) {
        const mapping = quantity.map(i => Number(i))
        const total = mapping.reduce((a, b) => a + b, 0)
        return total
      }
      return 0
    },
    resolveStoreName(id) {
      const str = this.storeOptions.find(o => o._id === id)
      if (str) {
        return str.name || ''
      }
      return ''
    },
    resolveSFName(id) {
      const sf = this.serviceFormOptions.find(o => o._id === id)
      if (sf) {
        return sf.stringID || ''
      }
      return ''
    },
    sendFileNo(fileNo) {
      this.$emit('send-file-no', fileNo)
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.img-preview-block{
      box-sizing: border-box;
      width: 38px;
      height: 38px;
      border: 1px solid #D2DCEA;
      border-radius: 5px;
  }
</style>
