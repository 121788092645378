import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchInventoryRequests(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('inventory/inventory-request', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteInventoryRequest(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`inventory/inventory-request/${id}/delete`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchRelatedInventoryRequestHistoryFileNo(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('inventory/inventory-request/related-items/by-file-no', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchRelatedInventoryRequestHistoryItemFileNo(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('inventory/inventory-request/related-items/by-item-file-no', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
