<template>
  <b-dropdown-form
    class="py-1 dropdown-filter-form-n"
  >
    <b-row>
      <b-col cols="10">
        <p>Filters</p>
      </b-col>
      <b-col cols="2">
        <feather-icon
          icon="XIcon"
          size="16"
          class="align-right"
          @click="$emit('emitHideFilter')"
        />
      </b-col>
    </b-row>
    <b-form-group
      label="Category"
      @click.native.stop
    >
      <v-select
        v-model="categoryValue"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        label="name"
        multiple
        placeholder="Select from list"
        :options="categoryOptions"
        :clearable="false"
        :reduce="name => name._id"
      />
    </b-form-group>
    <hr class="dividerHR filter-divider">
    <b-form-group
      label="User"
      @click.native.stop
    >
      <v-select
        v-model="userValue"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        label="name"
        multiple
        placeholder="Select from list"
        :options="userOptions"
        :clearable="false"
        :reduce="name => name._id"
      />
    </b-form-group>
    <hr class="dividerHR filter-divider">
    <b-form-group
      label="Status"
      @click.native.stop
    >
      <v-select
        v-model="statusFilterValue"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        label="label"
        multiple
        placeholder="Select from list"
        :options="statusOptions"
        :clearable="false"
        :reduce="status => status.value"
      />
    </b-form-group>
    <b-button
      variant="outline-primary"
      size="sm"
      class="mr-1"
      @click="clearFilter"
    >
      Clear All
    </b-button>
    <b-button
      variant="primary"
      size="sm"
      @click="applyFilter"
    >
      Apply Filter
    </b-button>
  </b-dropdown-form>
</template>

<script>
import {
  BDropdownForm, BFormGroup, BButton, BRow, BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BButton,
    BDropdownForm,
    BFormGroup,
    BRow,
    BCol,
    vSelect,
  },
  props: {
    category: {
      type: [Array, null],
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
    categoryOptions: {
      type: Array,
      required: true,
    },
    user: {
      type: [Array, null],
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
    userOptions: {
      type: Array,
      required: true,
    },
    statusFilter: {
      type: [Array, null],
      // eslint-disable-next-line vue/require-valid-default-prop
      default: null,
    },
    statusOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      categoryValue: JSON.parse(JSON.stringify(this.category)) || [],
      userValue: JSON.parse(JSON.stringify(this.user)) || [],
      statusFilterValue: JSON.parse(JSON.stringify(this.statusFilter)) || [],
    }
  },
  watch: {
    category: {
      immediate: true,
      handler(val) {
        this.categoryValue = JSON.parse(JSON.stringify(val)) || []
      },
    },
    user: {
      immediate: true,
      handler(val) {
        this.userValue = JSON.parse(JSON.stringify(val)) || []
      },
    },
    statusFilter: {
      immediate: true,
      handler(val) {
        this.statusFilterValue = JSON.parse(JSON.stringify(val)) || []
      },
    },
  },
  methods: {
    applyFilter() {
      this.$emit('update:category', this.categoryValue)
      this.$emit('update:user', this.userValue)
      this.$emit('update:statusFilter', this.statusFilterValue)

      this.$emit('emitHideFilter')
    },
    clearFilter() {
      this.categoryValue = []
      this.userValue = []
      this.statusFilterValue = []
      this.$emit('update:category', this.categoryValue)
      this.$emit('update:user', this.userValue)
      this.$emit('update:statusFilter', this.statusFilterValue)

      this.$emit('emitHideFilter')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
