<template>

  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0 bg-white"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="content-header-left"
          >
            <h3 style="color: #000;">
              <span class="align-middle">Related Inventory Requests History</span>
            </h3>
          </b-col>
          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <a
                href="javascript:void(0)"
                class="nav-link nav-link-search"
                @click="showSearchBar = !showSearchBar"
              >
                <feather-icon
                  icon="SearchIcon"
                  size="21"
                  class="mr-50"
                />
                <span class="align-middle">Search</span>
              </a>
              <b-input-group
                v-if="showSearchBar"
                class="input-group-merge mr-50"
              >
                <b-form-input
                  v-model="searchQuery"
                  placeholder="Search..."
                />
                <b-input-group-append is-text>
                  <feather-icon
                    icon="XIcon"
                    class="cursor-pointer"
                    @click="searchQuery = ''; showSearchBar = !showSearchBar"
                  />
                </b-input-group-append>
              </b-input-group>

              <b-dropdown
                id="dropdown-all-related-inventory-request"
                ref="filter_dropdown_all_related_inventory_request"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="dropdown-modal"
                right
                variant="link"
              >
                <template #button-content>
                  <feather-icon
                    icon="FilterIcon"
                    size="21"
                    class="align-middle mr-50"
                  />
                  <span class="mr-1">Filters</span>
                </template>
                <related-inventory-request-filter
                  :category.sync="categoryFilter"
                  :category-options="categoryOptions"
                  :user.sync="userFilter"
                  :user-options="userOptions"
                  :status-filter.sync="statusFilter"
                  :status-options="statusOptions"
                  @emitHideFilter="hideFilter"
                  @emitShowFilter="showFilter"
                />
              </b-dropdown>
            </div>
          </b-col>
        </b-row>
        <div v-if="categoryFilter.length || userFilter.length || statusFilter.length">
          <hr class="dividerHR filter-divider">
          <b-row>

            <!-- Per Page -->
            <b-col
              cols="12"
              md="12"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <ul
                class="list-inline d-inline-block mb-1"
              >
                <li
                  v-for="categoryVal in categoryFilter"
                  :key="categoryVal"
                  class="list-inline-item"
                >
                  <b-form-tag
                    class="mb-50"
                    variant="danger"
                    @remove="removeFromCategoryFilter(categoryVal)"
                  >
                    <span class="">{{ resolveCategoryName(categoryVal) }}</span>
                  </b-form-tag>
                </li>
                <li
                  v-for="user in userFilter"
                  :key="user"
                  class="list-inline-item"
                >
                  <b-form-tag
                    class="mb-50"
                    variant="danger"
                    @remove="removeFromUserFilter(user)"
                  >
                    <span class="text-capitalize"> <span class="text-capitalize">{{ resolveUserName(user) }}</span></span>
                  </b-form-tag>
                </li>
                <li
                  v-for="statusVal in statusFilter"
                  :key="statusVal"
                  class="list-inline-item"
                >
                  <b-form-tag
                    class="mb-50"
                    variant="danger"
                    @remove="removeFromStatusFilter(statusVal)"
                  >
                    <span class="text-capitalize">{{ statusVal.replace("-", " ") }}</span>
                  </b-form-tag>
                </li>
                <li
                  class="list-inline-item"
                >
                  <span
                    class="text-danger clear-filter-text mb-50"
                    @click="clearAllFilters"
                  >
                    Clear Filters
                  </span>
                </li>
              </ul>
            </b-col>
          </b-row>
        </div>
      </div>

      <b-table
        ref="refAllRelatedInventoryRequestsItemTable"
        class="position-relative has_padding event_list"
        :items="fetchRelatedInventoryRequestHistory"
        responsive
        :fields="tableColumns"
        primary-key="_id"
        :sort-by.sync="sortBy"
        show-empty
        no-sort-reset
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #empty="scope">
          <b-row v-if="searchQuery">
            <b-col>
              <div class="empty_block vendor_empty">
                <h4>Sorry, we couldn’t find any results.</h4>
                <span class="align-middle">Please try again</span>
              </div>
            </b-col>
          </b-row>
          <b-row v-else-if="fileNoQuery.length || itemFileNoQuery.length">
            <b-col>
              <div class="empty_block">
                <p
                  v-if="fileNoQuery.length"
                  class="align-middle"
                >
                  There’s no inventory request history for File No. {{ Array.isArray($route.query.fileNo) ? $route.query.fileNo.join(" ") : $route.query.fileNo }}
                </p>
                <p
                  v-if="itemFileNoQuery.length"
                  class="align-middle"
                >
                  There’s no inventory request history for Item File No. {{ Array.isArray($route.query.itemFileNo) ? $route.query.itemFileNo.join(" ") : $route.query.itemFileNo }}
                </p>
                <br>
              </div>
            </b-col>
          </b-row>
          <b-row v-else>
            <b-col>
              <div class="text-center">
                <span class="align-middle">{{ scope.emptyText }}</span>
              </div>
            </b-col>
          </b-row>
        </template>
        <!-- Column: Role -->
        <template #cell(requestStringID)="data">
          <div class="text-nowrap">
            <b-button
              v-if="canViewThisAction('show', 'InventoryRequest')"
              v-ripple.400="'rgba(30, 30, 30, 0.15)'"
              variant="link"
              class="weight-600 align-middle detail-view-id"
              :to="{ name: 'inventory-inventory-request-view', params: { id: data.item.request._id } }"
            >
              {{ data.item.requestStringID }}
            </b-button>
            <b-button
              v-else
              v-ripple.400="'rgba(30, 30, 30, 0.15)'"
              variant="none"
              class="text-bold-black align-middle detail-view-id not-button"
            >
              {{ data.item.requestStringID }}
            </b-button>
          </div>
        </template>

        <template #cell(item)="data">
          <div class="d-flex">
            <b-img
              class="mb-1 mb-sm-0 user__avatar img-preview-block"
              :src="data.item.item.image ? data.item.item.image : defaultImage"
            />
            <div
              class="ml-1"
            >
              <span class="text-bold-black">{{ data.item.item.name || '-' }}</span>
              <br>
              <span class="text-muted">{{ data.item.item.sku || '-' }}</span>
            </div>
          </div>
        </template>

        <!-- <template #cell(itemFileNo)="data">
          <span>{{ data.item.request.fileNo }}</span>
        </template> -->

        <template #cell(category)="data">
          <div class="text-nowrap">
            <span class="">{{ resolveCategoryName(data.item.category) }}</span>
          </div>
        </template>

        <template #cell(totalQuantity)="data">
          <div class="text-nowrap">
            <span class="">{{ data.item.totalQuantity }}</span>
          </div>
        </template>

        <template #cell(createdBy)="data">
          <div class="text-nowrap">
            <span class="">{{ data.item.createdBy.name }}</span>
            <br>
            <span class="">{{ dateFormatWithTime(data.item.createdAt) }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            :variant="`light-${resolveInventoryRequestStatusVariant(data.item.request.status)}`"
            class="text-capitalize"
          >
            {{ data.item.request.status }}
          </b-badge>
        </template>
      </b-table>
      <div
        v-if="totalInventoryRequests > perPage"
        class="mx-2 mb-2"
      >
        <b-row>
          <!-- number of items -->
          <b-col class="pagination-content">
            <span class="text-muted">Showing {{ dataMeta.from }} - {{ dataMeta.to }} of {{ dataMeta.of }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            class="d-flex pagination-content"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalInventoryRequests"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              align="center"
              prev-text="Prev"
              next-text="Next"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
                <span>Prev</span>
              </template>
              <template #next-text>
                <span>Next</span>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BInputGroup, BInputGroupAppend,
  BBadge, BDropdown, BPagination, BButton, BFormTag, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useUtils as useAclUtils } from '@/libs/acl/custom'

import store from '@/store'

import useAllRelatedInventoryRequestList from './useAllRelatedInventoryRequestHistoryList'
import inventoryRequestStoreModule from '../inventoryRequestStoreModule'
import RelatedInventoryRequestFilter from './RelatedInventoryRequestFilter.vue'

export default {
  components: {
    BButton,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BBadge,
    BDropdown,
    BPagination,
    BInputGroup,
    BInputGroupAppend,
    BFormTag,
    BImg,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,

    RelatedInventoryRequestFilter,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      // eslint-disable-next-line global-require
      defaultImage: require('@/assets/images/placeholder/placeholder_image.png'),
      categoryOptions: [],
      userOptions: [],
      statusOptions: [
        { label: 'Completed', value: 'completed' },
        { label: 'Cancelled', value: 'cancelled' },
        { label: 'Open', value: 'open' },
        { label: 'Collected', value: 'collected' },
        { label: 'Returned', value: 'returned' },
      ],
    }
  },
  beforeMount() {
    this.$http.get('inventory/category/respond-with/name-and-id')
      .then(response => {
        this.categoryOptions = response.data.categories ?? []
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    this.$http.get('directory/teams/respond-with/user-options')
      .then(response => {
        this.userOptions = response.data.userOptions ?? []
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },

  methods: {
    hideFilter() {
      this.$refs.filter_dropdown_all_related_inventory_request.hide(true)
    },
    showFilter() {
      this.$refs.filter_dropdown_all_related_inventory_request.show(true)
    },
    removeFromStatusFilter(item) {
      const index = this.statusFilter.indexOf(item)
      if (index > -1) {
        this.statusFilter.splice(index, 1)
      }
    },
    removeFromCategoryFilter(item) {
      const index = this.categoryFilter.indexOf(item)
      if (index > -1) {
        this.categoryFilter.splice(index, 1)
      }
    },
    removeFromUserFilter(user) {
      const findIndex = this.userOptions.find(obj => obj._id === user)
      if (findIndex) {
        this.userFilter.splice(findIndex, 1)
      }
    },
    filterFileNoQuery(fileNo) {
      if (fileNo) {
        this.fileNoQuery = [fileNo]
        this.itemFileNoQuery = []
      } else {
        this.fileNoQuery = []
      }
    },
    filterItemFileNoQueryArray(fileNo, itemFileNo) {
      // if (fileNo) {
      //   this.itemFileNoQuery = fileNo
      // } else {
      //   this.itemFileNoQuery = []
      // }
      this.fileNoQuery = fileNo
      this.singleItemFileNo = ''
      this.itemFileNoQuery = itemFileNo
      this.searchQuery = ''
      this.showSearchBar = false
      this.currentRequest = this.$route.params.id
      this.clearAllFilters()
    },
    filterItemFileNoQuery(fileNo) {
      this.singleItemFileNo = fileNo
      this.searchQuery = fileNo
      this.showSearchBar = true
      this.clearAllFilters()
    },
    clearAllFilters() {
      this.categoryFilter = []
      this.userFilter = []
      this.statusFilter = []
    },
    resolveCategoryName(id) {
      const user = this.categoryOptions.find(o => o._id === id)
      if (user) {
        return user.name || ''
      }
      return ''
    },
    resolveUserName(id) {
      const request = this.userOptions.find(o => o._id === id)
      if (request) {
        return request.name || ''
      }
      return ''
    },
    resolveInventoryRequestItemQuantity(quantity) {
      if (quantity) {
        const mapping = quantity.map(i => Number(i))
        const total = mapping.reduce((a, b) => a + b, 0)
        return total
      }
      return 0
    },
  },
  setup() {
    const showSearchBar = ref(false)
    const showFilterOptions = ref(false)
    const INVENTORY_REQUEST_APP_STORE_MODULE_NAME = 'app-all-inventory-requests'

    // Register module
    if (!store.hasModule(INVENTORY_REQUEST_APP_STORE_MODULE_NAME)) store.registerModule(INVENTORY_REQUEST_APP_STORE_MODULE_NAME, inventoryRequestStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVENTORY_REQUEST_APP_STORE_MODULE_NAME)) store.unregisterModule(INVENTORY_REQUEST_APP_STORE_MODULE_NAME)
    })

    const { canViewThisAction } = useAclUtils()

    const {
      fetchRelatedInventoryRequestHistory,
      tableColumns,
      perPage,
      currentPage,
      totalInventoryRequests,
      inventoryRequestItemData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      fileNoQuery,
      itemFileNoQuery,
      singleItemFileNo,
      refAllRelatedInventoryRequestsItemTable,
      refetchData,

      // UI
      resolveInventoryRequestStatusVariant,

      // Extra Filters
      categoryFilter,
      userFilter,
      statusFilter,
      currentRequest,
    } = useAllRelatedInventoryRequestList()

    return {

      // Sidebar

      fetchRelatedInventoryRequestHistory,
      tableColumns,
      perPage,
      currentPage,
      totalInventoryRequests,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      fileNoQuery,
      itemFileNoQuery,
      singleItemFileNo,
      refAllRelatedInventoryRequestsItemTable,
      refetchData,
      showSearchBar,
      showFilterOptions,
      inventoryRequestItemData,

      // Filter
      avatarText,
      canViewThisAction,

      // UI
      resolveInventoryRequestStatusVariant,

      // Extra Filters
      categoryFilter,
      userFilter,
      statusFilter,
      currentRequest,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.img-preview-block{
      box-sizing: border-box;
      width: 38px;
      height: 38px;
      border: 1px solid #D2DCEA;
      border-radius: 5px;
  }
 .pagination-content{
      justify-content: end;
 }
 @media (max-width: 767px) {
  .pagination-content{
      justify-content: start;
 }
}
</style>
