<template>

  <div class="service__details">
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="4"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title">
              {{ inventoryRequest.stringID }}
            </h1>
            <p class="log_info"> Created by {{ inventoryRequest.createdBy ? inventoryRequest.createdBy.name : '' }} on {{ dateFormatWithTime(inventoryRequest.createdAt) }}
              <br>Last updated on {{ dateFormatWithTime(inventoryRequest.updatedAt) }}
            </p>
          </b-col>
        </b-row>
      </b-col>

      <!-- Content Right -->
      <b-col
        class="content-header-right text-md-right mb-1"
        md="8"
        cols="12"
      >
        <div class="user_block ac_type">
          <div class="user_avatar" />
          <div class="user_info">
            <h6>Status</h6>
            <span
              class="text-capitalize"
              style="color: #000; font-weight: 500;"
            >
              {{ inventoryRequest.status }}
            </span>
          </div>
        </div>
        <div class="user_block pr_stage_red_type">
          <div class="user_avatar" />
          <div class="user_info">
            <h6>Service Request</h6>
            <b-button
              v-if="canViewThisAction('show', 'ServiceRequest')"
              v-ripple.400="'rgba(30, 30, 30, 0.15)'"
              variant="link"
              class="weight-600 align-middle detail-view-id cursor-pointer"
              :to="{ name: 'operation-service-requests-show', params: { id: inventoryRequest.serviceRequest || 0 } }"
            >
              {{ inventoryRequest.serviceRequestStringID }}
            </b-button>
            <b-button
              v-else
              v-ripple.400="'rgba(30, 30, 30, 0.15)'"
              variant="none"
              class="weight-600 align-middle detail-view-id not-button"
            >
              {{ inventoryRequest.serviceRequestStringID }}
            </b-button>
          </div>
        </div>
        <div class="user_block pr_stage_type">
          <div class="user_avatar" />
          <div class="user_info">
            <h6>Service Form</h6>
            <b-button
              v-if="canViewThisAction('show', 'ServiceForm')"
              v-ripple.400="'rgba(30, 30, 30, 0.15)'"
              variant="link"
              class="weight-600 align-middle detail-view-id cursor-pointer"
              :to="{ name: 'operation-service-forms-show', params: { id: inventoryRequest.serviceForm || 0 } }"
            >
              {{ inventoryRequest.serviceFormStringID }}
            </b-button>
            <b-button
              v-else
              v-ripple.400="'rgba(30, 30, 30, 0.15)'"
              variant="none"
              class="weight-600 align-middle detail-view-id not-button"
            >
              {{ inventoryRequest.serviceFormStringID }}
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>
    <!-- Table Container Card -->

    <b-card
      class="mb-3 p-2 calllog-details serviceForm_details"
    >
      <b-row
        class="content-header"
        style="flex-wrap: nowrap;"
      >

        <!-- Content Left -->
        <b-col
          class="content-header-left mb-2"
          md="12"
        >
          <h3>Inventory Request Details</h3>
        </b-col>
      </b-row>
      <b-table-simple
        borderless
        responsive
        class="particulars_of_deceased d-none d-md-block"
      >
        <b-tbody>
          <b-tr>
            <b-th width="230px">
              Requester
            </b-th>
            <b-td>{{ inventoryRequest.createdBy ? inventoryRequest.createdBy.name : '' }}</b-td>
            <b-th width="230px">
              Service Request Date/Time
            </b-th>
            <b-td>{{ dateFormatWithTime(inventoryRequest.serviceDate) }}</b-td>
          </b-tr>
          <b-tr>
            <b-th width="230px">
              Customer Name
            </b-th>
            <b-td>{{ inventoryRequest.customerName }}</b-td>
            <b-th width="230px">
              Store
            </b-th>
            <b-td>
              <span
                v-for="(option, indx) in inventoryRequest.stores"
                :key="indx"
              >
                {{ option.name }} <span v-if="indx + 1 != inventoryRequest.stores.length">, </span>
              </span>
            </b-td>
          </b-tr>
          <b-tr>
            <b-th width="230px">
              File No.
            </b-th>
            <b-td>
              {{ inventoryRequest.fileNo }}
              <b-button
                type="button"
                variant="flat-primary"
                :disabled="inventoryRequest.fileNo ? false : true"
                @click="filterFileNo(inventoryRequest.fileNo)"
              >
                Check history
              </b-button>
            </b-td>
            <b-th width="230px">
              Remarks
            </b-th>
            <b-td>{{ inventoryRequest.remarks }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>

      <b-table-simple
        borderless
        responsive
        class="particulars_of_deceased d-block d-md-none"
      >
        <b-tbody>
          <b-tr>
            <b-th width="230px">
              Requester
            </b-th>
            <b-td>{{ inventoryRequest.createdBy ? inventoryRequest.createdBy.name : '' }}</b-td>
            <b-th width="230px">
              Customer Name
            </b-th>
            <b-td>{{ inventoryRequest.customerName }}</b-td>
          </b-tr>
          <b-tr>
            <b-th width="230px">
              File No.
            </b-th>
            <b-td>
              {{ inventoryRequest.fileNo }}
              <b-button
                type="button"
                variant="flat-primary"
                @click="filterFileNo(inventoryRequest.fileNo)"
              >
                Check history
              </b-button>
            </b-td>
            <b-th width="230px">
              Service Request <br> Date/Time
            </b-th>
            <b-td>{{ dateFormatWithTime(inventoryRequest.serviceDate) }}</b-td>
          </b-tr>
          <b-tr>
            <b-th width="230px">
              Store
            </b-th>
            <b-td>
              <span
                v-for="(option, indx) in inventoryRequest.stores"
                :key="indx"
              >
                {{ option.name }} <span v-if="indx + 1 != inventoryRequest.stores.length">, </span>
              </span>
            </b-td>
            <b-th width="230px">
              Remarks
            </b-th>
            <b-td> {{ inventoryRequest.remarks }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>

      <hr class="dividerHR">

      <b-row
        class="content-header"
        style="flex-wrap: nowrap;"
      >
        <!-- Content Left -->
        <b-col
          class="content-header-left"
          md="9"
        >
          <h3>
            <span class="align-middle">Requested Inventory Item(s)</span>
          </h3>
        </b-col>
        <b-col
          class="content-header-right text-md-right"
          md="3"
        >
          <b-button
            v-if="inventoryRequest.status === 'collected' || inventoryRequest.status === 'completed' || inventoryRequest.status === 'returned'"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            class="mr-1"
            :disabled="inventoryRequest.status === 'returned' ? true : false"
            @click="openReturnModal"
          >
            <span class="align-middle">Return Item(s)</span>
          </b-button>
          <b-button
            v-if="inventoryRequest.status === 'collected' || inventoryRequest.status === 'returned'"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-success"
            style="background-color: rgba(40,199,111,.2)"
            :disabled="inventoryRequest.status === 'returned' ? true : false"
          >
            <feather-icon
              icon="CheckIcon"
              class="mr-50"
            />
            <span class="align-middle">Processed</span>
          </b-button>
          <b-button
            v-if="inventoryRequest.status === 'completed'"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-success"
            style="background-color: rgba(40,199,111,.2)"
          >
            <feather-icon
              icon="CheckSquareIcon"
              class="mr-50"
            />
            <span class="align-middle">Verified</span>
          </b-button>
          <b-button
            v-if="inventoryRequest.status === 'open'"
            v-b-modal.modal-requested-inventory-item
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            type="button"
            variant="success"
          >
            <span class="align-middle">Process Request</span>
          </b-button>
        </b-col>
      </b-row>
      <requested-inventory-item
        :total-items="inventoryRequest.totalItems"
        :total-quantity="inventoryRequest.totalQuantity"
        :fetch-inventory-requested-item="inventoryRequestItems"
        :return-table="returnTable"
        @send-file-no="filterItemFileNo"
      />
      <div
        v-if="inventoryRequest.status === 'collected' || inventoryRequest.status === 'completed'"
        class="verify-request-section"
      >
        <b-row class="verify-request-wrapper">
          <b-col
            md="5"
            lg="5"
            sm="12"
            class="request-img-section"
          >
            <b-img
              src="@/assets/images/admin/verify-request.png"
              alt="Verify Request"
            />
          </b-col>
          <b-col
            md="5"
            lg="5"
            sm="12"
            class="request-message-section"
          >
            <div
              v-if="inventoryRequest.status === 'collected'"
            >
              <b>Check the necessary forms in Service Request
                <b-button
                  type="button"
                  variant="flat-primary"
                  :to="{ name: 'operation-service-requests-show', params: { id: inventoryRequest.serviceRequest } }"
                >
                  {{ inventoryRequest.serviceRequestStringID }}
                </b-button>.
                <br>
                If all is in order, tap the following button to complete this request.
              </b>
              <br>
              <b-button
                type="button"
                variant="primary"
                class="mt-1"
                @click="updateStatus('completed')"
              >
                I have verified this Request
              </b-button>
            </div>
            <div
              v-if="inventoryRequest.status === 'completed'"
            >
              <b>This Inventory Request has been verified (via  <b-button
                type="button"
                variant="flat-primary"
                :to="{ name: 'operation-service-requests-show', params: { id: inventoryRequest.serviceRequest} }"
              >
                {{ inventoryRequest.serviceRequestStringID }}
              </b-button>).
              </b>
              <br>
              <b-button
                type="button"
                variant="flat-primary"
                @click="updateStatus('collected')"
              >
                Undo
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <b-card
      ref="related_detail"
      no-body
      class="mb-3 p-2 without__bg extra__bg"
    >
      <related-inventory-request-history
        ref="relatedInventoryRequestComponent"
      />
    </b-card>

    <b-card
      class="mb-3 mt-3 p-2"
    >
      <b-row
        class="content-header"
      >
        <!-- Content Left -->
        <b-col
          class="content-header-left mb-2 p-2"
          cols="12"
          md="12"
        >
          <h4>
            <feather-icon
              icon="ListIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle">Activity Logs</span>
          </h4>
        </b-col>
      </b-row>
      <app-timeline>
        <!-- FINANCIAL REPORT -->
        <app-timeline-item
          v-for="(opt, key) in activityLogs"
          :key="key"
          :variant="opt.variant"
        >

          <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
            <h6>{{ opt.title }}</h6>
            <small class="text-muted">{{ dateFormatWithTime(opt.time) }}</small>
          </div>
        </app-timeline-item>
      </app-timeline>
    </b-card>
    <!-- requested inventory item preview modal -->
    <b-modal
      id="modal-requested-inventory-item"
      ref="requested-inventory-item-modal"
      modal-class="status-update-modal"
      size="lg"
      centered
      no-stacking
      no-close-on-backdrop
      @ok="processInventoryRequest"
      @cancel="closeRequestedInventoryItemModal"
    >
      <template #modal-header>
        <div class="stock-items-modal-header">
          <feather-icon
            icon="PackageIcon"
            style="color: #7367F0; margin-bottom: 7px;"
            size="60"
          />
          <div class="stock-in-item-modal-heading-block">
            <h4 style="font-weight: 500; font-size: 21px; color: #000000;">
              Process request and stock out selected item(s)?
            </h4>
            <p style="color: #333333;">
              Please review the inventory item(s) below. Items will be stocked out from each store.
            </p>
          </div>
        </div>
      </template>
      <b-table-simple
        responsive
        sticky-header
        class="shadow-table"
      >
        <b-thead>
          <b-tr>
            <b-th style="height: 0px !important;">
              Item
            </b-th>
            <b-th style="height: 0px !important;">
              Store
            </b-th>
            <b-th style="height: 0px !important;">
              Quantity
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            v-for="(inventoryItem, inventoryIndex) in inventoryRequestItems"
            :key="inventoryIndex"
          >
            <b-td>
              <div class="d-flex">
                <b-img
                  class="mb-1 mb-sm-0 user__avatar img-preview-block"
                  :src="inventoryItem.item.image ? inventoryItem.item.image : defaultImage"
                />
                <div
                  class="ml-1"
                >
                  <span class="text-bold-black">{{ inventoryItem.item.name }}</span>
                  <br>
                  <span class="text-muted">{{ inventoryItem.item.sku }}</span>
                </div>
              </div></b-td>
            <b-td>
              <span>
                {{ inventoryItem.store.name }}
                <br>
                {{ inventoryItem.store.department ? inventoryItem.store.department.name : '' }}
              </span>
            </b-td>
            <b-td> - {{ inventoryItem.totalQuantity }} </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <div class="d-flex justify-content-between">
        <p><span class="text-bold-black mr-75">Inventory Item(s)</span> {{ inventoryRequest.totalItems }} </p>
        <p><span class="text-bold-black">Total Quantity</span> <span class="ml-75"> {{ inventoryRequest.totalQuantity }} </span></p>
      </div>
      <hr class="mt-0">
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="outline-primary"
          @click="cancel()"
        >
          No, go back
        </b-button>
        <b-button
          variant="primary"
          type="button"
          @click="ok()"
        >
          <span class="align-middle">Yes, proceed</span>
        </b-button>
      </template>
    </b-modal>
    <!-- ./ requested inventory item preview modal -->
    <!-- return inventory item preview modal -->
    <b-modal
      id="modal-return-inventory-item"
      ref="requested-inventory-item-modal"
      modal-class="status-update-modal"
      size="lg"
      centered
      no-stacking
      no-close-on-backdrop
      @ok="submitReturnForm"
      @cancel="closeReturnInventoryItemModal"
    >
      <template #modal-header>
        <div class="stock-items-modal-header">
          <feather-icon
            icon="CornerUpLeftIcon"
            style="color: #FF9F43; margin-bottom: 7px;"
            size="60"
          />
          <div class="stock-in-item-modal-heading-block">
            <h4 style="font-weight: 500; font-size: 21px; color: #000000;">
              Return item(s) to store?
            </h4>
            <p style="color: #333333;">
              The following item(s) will be stocked-in, and this inventory request <br> will be updated to “Returned”.<b> This cannot be undone.</b>
            </p>
          </div>
        </div>
      </template>
      <div class="full-return-checkbox">
        <b-form-checkbox
          v-model="fullReturn"
          disabled
          @input="fullReturnCheckbox()"
        >
          <span class="font_normal">Full return</span>
        </b-form-checkbox>
      </div>
      <b-table-simple
        responsive
        sticky-header
        class="shadow-table"
      >
        <b-thead>
          <b-tr>
            <b-th style="height: 0px !important;">
              Item
            </b-th>
            <b-th style="height: 0px !important;">
              Store
            </b-th>
            <b-th style="height: 0px !important;">
              Quantity
            </b-th>
            <b-th style="height: 0px !important;">
              Returned QTY
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            v-for="(rItem, index) in inventoryRequestItems"
            :key="index"
          >
            <b-td>
              <div class="d-flex">
                <b-img
                  class="mb-1 mb-sm-0 user__avatar img-preview-block"
                  :src="rItem.item.image ? rItem.item.image : defaultImage"
                />
                <div
                  class="ml-1"
                >
                  <span class="text-bold-black">{{ rItem.item.name }}</span>
                  <br>
                  <span class="text-muted">{{ rItem.item.sku }}</span>
                </div>
              </div></b-td>
            <b-td>
              <span>
                {{ rItem.store.name }}
                <br>
                {{ rItem.store.department ? rItem.store.department.name : '' }}
              </span>
            </b-td>
            <b-td> {{ rItem.totalQuantity }}</b-td>
            <b-td>
              <validation-provider
                #default="{ errors }"
                name="Quantity"
                :vid="`quantity_${index}`"
                rules="required|numeric"
              >
                <b-form-group
                  :label-for="`quantity_${index}`"
                  :class="{ 'is-invalid': errors.length }"
                >
                  <b-form-input
                    :id="`quantity_${index}`"
                    v-model="returnQty[index]"
                    name="quantity"
                    placeholder="0"
                    :readonly="readonlyVal"
                    @input="stockQuantityValidation(rItem.item._id, index, rItem.quantity, rItem.returnedQuantity, returnQty[index])"
                    @keypress="validateNumberInput"
                    @paste="validateNumberPaste"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="returnError[index].error"
                    class="text-danger"
                  >
                    {{ returnError[index].message }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <div class="d-flex justify-content-between">
        <p><span class="text-bold-black mr-75">Inventory Item(s)</span> {{ inventoryRequest.totalItems }} </p>
        <p><span class="text-bold-black">Total Quantity</span> <span class="ml-75"> {{ inventoryRequest.totalQuantity }} </span></p>
        <p><span class="text-bold-black">Return Quantity</span> <span class="ml-75"> {{ totalReturnQuantity }} </span></p>
      </div>
      <hr class="mt-0">
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="outline-primary"
          @click="cancel()"
        >
          No, go back
        </b-button>
        <b-button
          variant="primary"
          type="button"
          :disabled="catchQuantityError"
          @click="ok()"
        >
          <span class="align-middle">Yes, proceed</span>
        </b-button>
      </template>
    </b-modal>
    <!-- ./ return inventory item preview modal -->
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import {
  BCard, BRow, BButton, BCol, BTableSimple, BThead, BTbody, BTr, BTh, BTd,
  VBToggle, VBTooltip, BImg, BFormGroup, BFormInput, BFormCheckbox,
} from 'bootstrap-vue'
import store from '@/store/index'
import { required } from '@validations'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import Ripple from 'vue-ripple-directive'

import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import RequestedInventoryItem from './requested-inventory-item/RequestedInventoryItem.vue'
import RelatedInventoryRequestHistory from './related-inventory-request-history/RelatedInventoryRequestHistory.vue'

const { canViewThisAction } = useAclUtils()
export default {
  components: {
    BCard,
    BRow,
    BButton,
    BCol,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
    BImg,
    BFormGroup,
    BFormInput,
    BFormCheckbox,

    RequestedInventoryItem,
    RelatedInventoryRequestHistory,

    AppTimeline,
    AppTimelineItem,

    ValidationProvider,
  },
  directives: { 'b-toggle': VBToggle, 'b-tooltip': VBTooltip, Ripple },
  data() {
    return {
      canViewThisAction,
      user: store.state.auth.userData,
      required,
      // eslint-disable-next-line global-require
      defaultImage: require('@/assets/images/placeholder/placeholder_image.png'),
      returnQty: [],
      returnError: [],
      inventoryRequest: {},
      inventoryRequestItems: [],
      activityLogs: [],
      stockInTransactions: [],
      fullReturn: false,
      readonlyVal: false,
      fileNoArray: [],
      itemFileNoArray: [],
      returnTable: false,
    }
  },
  computed: {
    totalReturnQuantity() {
      const returnQtyArray = this.returnQty.length
      if (returnQtyArray) {
        const mapping = this.returnQty.map(i => Number(i))
        const total = mapping.reduce((a, b) => a + b, 0)
        return total
      }
      return 0
    },
    catchQuantityError() {
      let errorFound = 0
      this.inventoryRequestItems.forEach((item, indx) => {
        const qty = item.totalQuantity - item.returnedQuantity
        // qty -= this.stockInTransactions.filter(i => i.item === item.item._id).reduce((n, { adjustedQuantity }) => n + adjustedQuantity, 0)
        if (this.returnQty[indx] > qty) {
          errorFound += 1
        }
      })
      if (!this.returnQty.length || !this.returnQty.filter(i => i > 0).length) {
        errorFound += 1
      }
      if (errorFound > 0) {
        return true
      }
      return false
    },
  },
  watch: {
    '$route.params.id': {
      immediate: true,
      handler() {
        this.getDetail()
      },
    },
  },
  mounted() {
    window.scrollTo(0, 0)
  },
  created() {
    this.getDetail()
  },
  methods: {
    getDetail() {
      this.$http.get(`inventory/inventory-request/${this.$route.params.id}`)
        .then(response => {
          this.inventoryRequest = response.data.inventoryRequest || {}
          this.activityLogs = response.data.inventoryRequest.activityLog.sort((a, b) => new Date(b.time) - new Date(a.time)) || []
          this.inventoryRequestItems = response.data.inventoryRequestItems || []
          this.stockInTransactions = response.data.stockInTransactions || []
          if (this.inventoryRequest.fileNo) {
            // this.filterFileNo(this.inventoryRequest.fileNo, false)
            this.fileNoArray.push(this.inventoryRequest.fileNo)
          }
          this.inventoryRequestItems.forEach(obj => {
            if (obj.fileNo) {
              this.itemFileNoArray.push(obj.fileNo)
            }
            if (obj.returnedQuantity > 0) {
              this.returnTable = true
            }
            const errObj = {
              error: false,
              message: '',
            }
            this.returnError.push(errObj)
          })
          this.filterItemFileNoArray(this.fileNoArray, this.itemFileNoArray)
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    validateNumberInput(event) {
      if (event.key && !/^-?\d*[.]?\d*$/.test(event.key)) {
        event.preventDefault()
      }
    },
    validateNumberPaste(event) {
      const text = (event.originalEvent || event).clipboardData.getData('text/plain')
      if (text && !/^-?\d*[.]?\d*$/.test(text)) {
        event.preventDefault()
      }
    },
    resolveInventoryRequestItemQuantity(quantity) {
      if (quantity) {
        const mapping = quantity.map(i => Number(i))
        const total = mapping.reduce((a, b) => a + b, 0)
        return total
      }
      return 0
    },
    stockQuantityValidation(itemID, itemIndex, stockQty, returnedQuantity, inputQty) {
      const qty = stockQty - returnedQuantity
      if (inputQty > qty) {
        this.returnError[itemIndex].error = true
        this.returnError[itemIndex].message = `Return quantity can not be more than ${qty}`
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Return quantity can not be more than ${qty} for this item!`,
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        })
      } else {
        this.returnError[itemIndex].error = false
      }
    },
    openReturnModal() {
      this.fullReturn = true
      this.fullReturnCheckbox()
      this.$root.$emit('bv::show::modal', 'modal-return-inventory-item', '')
    },
    fullReturnCheckbox() {
      if (this.fullReturn === true) {
        this.returnQty = []
        this.inventoryRequestItems.forEach((item, i) => {
          const qty = item.totalQuantity - item.returnedQuantity
          // qty -= this.stockInTransactions.filter(i => i.item === item.item._id).reduce((n, { adjustedQuantity }) => n + adjustedQuantity, 0)
          this.returnQty.push(qty)
          this.returnError[i].error = false
        })
        this.readonlyVal = true
      } else {
        this.returnQty = []
        this.readonlyVal = false
      }
    },
    filterFileNo() {
      if (this.$route.query.singleItemFileNo) {
        if (this.$refs.relatedInventoryRequestComponent) {
          // this.$refs.relatedInventoryRequestComponent.filterFileNoQuery(fileNo)
          this.$refs.relatedInventoryRequestComponent.filterItemFileNoQueryArray(this.fileNoArray, this.itemFileNoArray)
        }
      }
      const element = this.$refs.related_detail
      if (element) {
        const top = element.offsetTop - 135
        window.scrollTo({
          top,
          behavior: 'smooth',
        })
      }
    },
    filterItemFileNo(fileNo) {
      if (this.$refs.relatedInventoryRequestComponent) {
        this.$refs.relatedInventoryRequestComponent.filterItemFileNoQuery(fileNo)
        const element = this.$refs.related_detail
        if (element) {
          const top = element.offsetTop - 135
          window.scrollTo({
            top,
            behavior: 'smooth',
          })
        }
      }
    },
    filterItemFileNoArray(fileNo, itemFileNo) {
      if (this.$refs.relatedInventoryRequestComponent) {
        this.$refs.relatedInventoryRequestComponent.filterItemFileNoQueryArray(fileNo, itemFileNo)
      }
    },
    processInventoryRequest() {
      const formData = new FormData()
      const storesArray = []
      // let storeList = ''
      this.inventoryRequestItems.forEach(item => {
        const exists = storesArray.find(obj => obj.store === item.store._id)
        if (exists) {
          const itemExists = exists.items.findIndex(obj => obj.item === item.item._id)
          if (itemExists !== -1) {
            exists.totalQuantity -= -item.totalQuantity
            exists.items[itemExists].quantity -= -item.totalQuantity
          } else {
            const itemArray = {
              item: item.item._id,
              quantity: +item.totalQuantity,
            }
            exists.totalItems += 1
            exists.totalQuantity -= -item.totalQuantity
            exists.items.push(itemArray)
          }
        } else {
          const itemArray = {
            item: item.item._id,
            quantity: +item.totalQuantity,
          }
          const storeArr = {
            store: item.store._id,
            items: [itemArray],
            totalItems: 1,
            totalQuantity: +item.totalQuantity,
          }

          storesArray.push(storeArr)
        }
      })

      formData.append('storeData', JSON.stringify(storesArray))
      this.$http.post(`inventory/inventory-request/process/${this.$route.params.id}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(response => {
          this.inventoryRequest.status = 'collected'

          this.$swal({
            title: 'Inventory Request Processed',
            html: response.data.message || '',
            // eslint-disable-next-line global-require
            imageUrl: require('@/assets/images/icons/success.png'),
            imageWidth: 80,
            imageHeight: 80,
            imageAlt: 'Custom Icon',
            showCancelButton: false,
            cancelButtonText: '',
            allowOutsideClick: false,
            confirmButtonText: 'Ok',
            customClass: {
              confirmButton: 'btn btn-primary mr-1',
              cancelButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
        .catch(error => {
          if (error.data.errors) {
            error.data.errors.forEach(validationError => {
              if (validationError.param === 'items') {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: validationError.msg || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
    },
    closeRequestedInventoryItemModal() {
      this.$root.$emit('bv::hide::modal', 'modal-requested-inventory-item', '')
    },
    closeReturnInventoryItemModal() {
      this.$root.$emit('bv::hide::modal', 'modal-return-inventory-item', '')
      this.returnQty = []
      this.readonlyVal = false
      this.fullReturn = false
    },
    submitReturnForm() {
      const formData = new FormData()
      const storesArray = []
      const returnArray = []
      let full = 'yes'
      let fullCount = 0
      // let storeList = ''
      this.inventoryRequestItems.forEach((item, index) => {
        const rQty = this.returnQty[index] ? Number(this.returnQty[index]) : 0
        if (rQty > 0) {
          const returnItemArray = {
            item: item._id,
            quantity: rQty,
          }
          returnArray.push(returnItemArray)
          const exists = storesArray.find(obj => obj.store === item.store._id)
          if (exists) {
            const itemExists = exists.items.findIndex(obj => obj.item === item.item._id)
            if (itemExists !== -1) {
              exists.totalQuantity -= -rQty
              exists.items[itemExists].quantity -= -rQty
            } else {
              const itemArray = {
                item: item.item._id,
                quantity: +rQty,
              }
              exists.totalItems += 1
              exists.totalQuantity -= -rQty
              exists.items.push(itemArray)
            }
          } else {
            const itemArray = {
              item: item.item._id,
              quantity: +rQty,
            }
            const storeArr = {
              store: item.store._id,
              items: [itemArray],
              totalItems: 1,
              totalQuantity: +rQty,
            }

            storesArray.push(storeArr)
          }

          const qty = item.totalQuantity - item.returnedQuantity
          // qty -= this.stockInTransactions.filter(i => i.item === item.item._id).reduce((n, { adjustedQuantity }) => n + adjustedQuantity, 0)
          if (qty <= rQty) {
            fullCount += 1
          }
        } else {
          const qty = item.totalQuantity - item.returnedQuantity
          if (qty <= 0) {
            fullCount += 1
          }
        }
      })

      if (fullCount === this.inventoryRequestItems.length) {
        full = 'yes'
      } else {
        full = 'no'
      }

      formData.append('storeData', JSON.stringify(storesArray))
      formData.append('returnArray', JSON.stringify(returnArray))
      let updatedStatus = this.inventoryRequest.status
      if (this.fullReturn === true || full === 'yes') {
        updatedStatus = 'returned'
        formData.append('fullReturn', 'yes')
      } else {
        formData.append('fullReturn', 'no')
      }
      this.$http.post(`inventory/inventory-request/return/${this.$route.params.id}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(response => {
          this.inventoryRequest.status = updatedStatus
          // this.stockInTransactions = response.data.stockInTransactions || []
          this.getDetail()
          // this.returnTable = true

          this.$swal({
            title: 'Inventory Request Returned',
            html: response.data.message || '',
            // eslint-disable-next-line global-require
            imageUrl: require('@/assets/images/icons/success.png'),
            imageWidth: 80,
            imageHeight: 80,
            imageAlt: 'Custom Icon',
            showCancelButton: false,
            cancelButtonText: '',
            allowOutsideClick: false,
            confirmButtonText: 'Ok',
            customClass: {
              confirmButton: 'btn btn-primary mr-1',
              cancelButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
        .catch(error => {
          if (error.data.errors) {
            error.data.errors.forEach(validationError => {
              if (validationError.param === 'items') {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: validationError.msg || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
    },
    updateStatus(status) {
      let title = ''
      let msg = ''
      if (status === 'completed') {
        title = 'Verify this Request?'
        msg = 'Are you sure you want to verify this request?'
      } else {
        title = 'Undo Verification?'
        msg = 'Are you sure you want to undo the verification of this request?'
      }
      this.$swal({
        title,
        html: msg,
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        imageWidth: 80,
        imageHeight: 80,
        imageAlt: 'Custom Icon',
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: "Yes, I'm Sure",
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            const formData = new FormData()
            formData.append('status', status)

            this.$http.patch(`inventory/inventory-request/${this.$route.params.id}/status/update`, formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            })
              .then(() => {
                this.inventoryRequest.status = status
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Status updated successfully.',
                    icon: 'CheckSquareIcon',
                    variant: 'success',
                  },
                })
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
    },
  },
}
</script>
<style scoped>
.card.mb-3.p-2.without__bg.extra__bg::before {
    position: absolute;
    top: -24px;
    bottom: -24px;
    right: -24px;
    content: "";
}
.verify-request-section{
     background: #F5F8FB;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
}
.verify-request-wrapper {
    padding: 2rem;
}
.request-img-section{
  display: flex;
  align-items: end;
  justify-content: end;
}
@media (max-width: 767px) {
  .card.mb-3.p-2.without__bg.extra__bg::before {
    right: -16px;
}
  .verify-request-wrapper {
    padding: 1rem;
}
  .request-img-section{
  align-items: center;
  justify-content: center;
}
}
  /* requested inventory item modal */
  .modal .form-control {
    margin: 0;
}
  .stock-items-modal-header{
    margin: 20px auto 0px auto;
    text-align: center;
  }
    .img-preview-block{
      box-sizing: border-box;
      width: 38px;
      height: 38px;
      border: 1px solid #D2DCEA;
      border-radius: 5px;
  }
  .full-return-checkbox{
  display: flex;
  align-items: end;
  justify-content: end;
}
  /* ./ requested inventory item modal */
</style>
